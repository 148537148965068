import React from "react";
import Img from "gatsby-image";
import clientConfig from "../../client-config";
import styles from "./work-categories.module.css";
import { Link, graphql, StaticQuery } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsWithoutImages,
} from "../lib/helpers";
import { getWorkCategoryUrl } from "../lib/helpers";
import { getFluidGatsbyImage } from "gatsby-source-sanity";

export const workCategoriesQuery = graphql`
  query workCategoriesQuery {
    categories: allSanityWorkCategory(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          title
          publishedAt
          slug {
            current
          }
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }
  }
`;

const WorkCategories = () => {
  return (
    <StaticQuery
      query={workCategoriesQuery}
      render={({ categories }) => {
        const categoryNodes = categories
          ? mapEdgesToNodes(categories)
              .filter(filterOutDocsWithoutSlugs)
              .filter(filterOutDocsWithoutImages)
          : [];
        return (
          <section>
            <div className={styles.grid}>
              {categoryNodes.map((category) => {
                const props = getFluidGatsbyImage(
                  category.image.asset._id,
                  { maxWidth: 620 },
                  clientConfig.sanity
                );
                return (
                  <Link to={getWorkCategoryUrl(category.slug)} className={styles.link}>
                    <div className={styles.box}>
                      <Img className={styles.image} fluid={props} alt={category.title} />
                      <div className={styles.label}>{category.title}</div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </section>
        );
      }}
    />
  );
};

export default WorkCategories;
