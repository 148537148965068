import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";

import PortableText from "../components/portableText";

import SEO from "../components/seo";
import Layout from "../containers/layout";
import Section from "../components/section";
import Showreel from "../components/showreel";
import Container from "../components/container";
import WorkCategories from "../components/work-categories";

import { toPlainText } from "../lib/helpers";

import styles from "./work-category.module.css";

export const query = graphql`
  query WorkCategoryTemplateQuery($id: String!) {
    category: sanityWorkCategory(id: { eq: $id }) {
      id
      title
      showreelVimeoId
      slug {
        current
      }
      _rawContent
    }
  }
`;

const WorkCategoryTemplate = (props) => {
  const { data, errors } = props;
  const category = data && data.category;
  const { title, showreelVimeoId, _rawContent } = category;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}

      {/*{post && (*/}
      {/*  <SEO*/}
      {/*    title={post.title || "Untitled"}*/}
      {/*    description={toPlainText(post._rawExcerpt)}*/}
      {/*    image={post.mainImage}*/}
      {/*  />*/}
      {/*)}*/}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {showreelVimeoId && (
        <Showreel src={`https://player.vimeo.com/video/${showreelVimeoId}?background=1`} />
      )}

      <Section bg="darkGeo">
        <div className={styles.column}>
          <h1 className={styles.title}>{title}</h1>
          {_rawContent && <PortableText blocks={_rawContent} />}
        </div>
        <WorkCategories />
      </Section>
    </Layout>
  );
};

export default WorkCategoryTemplate;
